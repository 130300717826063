import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://287799f0b415e2a25b00b379f6004872@o4508411782823936.ingest.de.sentry.io/4508411788787792",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()